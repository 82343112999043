<template>
  <div class="gap-6 h-full">
    <gm-page-container v-if="toolInfoLoading" class="mt-8">
      <gm-spinner size="large" />
    </gm-page-container>

    <div v-if="!toolInfoLoading">
      <PageHeader
        title="CRO Auditor"
        :sub-title="toolInfo.description"
        :show-back-button="{ url: '/ai-tools', label: 'A.I. Tools' }"
        :tabs="tabs"
        v-model:active-tab="activeTab"
      >
        <template #actions>
          <gm-button primary label="New CRO Audit" icon="plus" @click="newAudit"></gm-button>
        </template>
      </PageHeader>
      <gm-page-container>
        <div class="min-w-full">
          <div v-if="haveLoadedAudit">
            <CroAuditDetail />
          </div>
          <div v-else>
            <NewCroAuditForm v-if="activeTab === 'New Audit'" />
            <SavedCroAudits v-if="activeTab === 'Saved Audits'" />
          </div>
        </div>
      </gm-page-container>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted, watch } from "vue";
import { useAiToolsStore } from "@/store/aiTools";
import { useCroAuditStore } from "@/store/aiTools/cro-audit";
import { useInterfaceStore } from "@/store/interface";
import { useRoute, useRouter } from "vue-router";
import PageHeader from "@/components/PageHeader.vue";
import NewCroAuditForm from "./NewCroAuditForm.vue";
import SavedCroAudits from "./SavedCroAudits.vue";
import CroAuditDetail from "./CroAuditDetail.vue";

// Variables
const aiToolsStore = useAiToolsStore();
const croAuditStore = useCroAuditStore();
const interfaceStore = useInterfaceStore();
const router = useRouter();
const route = useRoute();

const toolInfo = computed(() => aiToolsStore.loadedTool || { name: "", description: "" });
const toolInfoLoading = ref(true);
const auditRunning = computed(() => croAuditStore.auditRunning);
const auditResults = computed(() => croAuditStore.auditResults);

const tabs = computed(() => {
  const tabsArray = [];
  tabsArray.push("Saved Audits");
  tabsArray.push("New Audit");
  if (haveLoadedAudit.value) {
    tabsArray.push("Audit Report");
  } else {
    // If we don't have a loaded audit, then we can't show the Audit Report tab
    // So remove it from the array, if it exists
    const index = tabsArray.indexOf("Audit Report");
    if (index > -1) {
      tabsArray.splice(index, 1);
    }
  }
  return tabsArray;
});
const activeTab = ref("Saved Audits");

const haveAuditResults = computed(() => {
  const results = auditResults.value;
  return (
    !!results && (Array.isArray(results) ? results.length > 0 : Object.keys(results).length > 0)
  );
});

const haveLoadedAudit = computed(() => {
  // Check the value of loadedCroAudit in the croAudit store, and make sure it's not empty
  const loadedAudit = croAuditStore.loadedCroAudit;
  return !!loadedAudit && Object.keys(loadedAudit).length > 0;
});

const routeId = computed(() => route.params.id);

// Watch for changes to the activeTab and update the route
watch(activeTab, (newVal) => {
  if (newVal === "New Audit") {
    clearLoadedAudit();
    router.push("/ai-tools/cro-audit/new");
  } else if (newVal === "Saved Audits") {
    clearLoadedAudit();
    router.push("/ai-tools/cro-audit");
  }
});

// Watch for changes to the tabs themselves
watch(tabs, (newVal) => {
  // If the new tabs array contains "Audit Report" and the activeTab is not "Audit Report", then change the activeTab to "Audit Report"
  if (newVal.includes("Audit Report") && activeTab.value !== "Audit Report") {
    activeTab.value = "Audit Report";
  }
});

// Watch for changes to the route
watch(routeId, (newVal) => {
  redirectBasedOnUrl();
});

function clearLoadedAudit() {
  croAuditStore.loadedCroAudit = {};
}

async function redirectBasedOnUrl() {
  if (routeId.value) {
    if (routeId.value === "new") {
      activeTab.value = "New Audit";
    } else {
      // Probably a saved audit, so try to load it
      try {
        await croAuditStore.fetchCroAudit(routeId.value);
      } catch (e) {
        console.log("Error fetching audit", e);
      }
    }
  } else {
    activeTab.value = "Saved Audits";
  }
}

function newAudit() {
  activeTab.value = "New Audit";
}

function backToAiTools() {
  router.push("/ai-tools");
}

onMounted(async () => {
  redirectBasedOnUrl();
  await aiToolsStore.fetchToolInfo("cro-audit");
  toolInfoLoading.value = false;
});

onUnmounted(() => {
  aiToolsStore.loadedTool = null;
});
</script>
