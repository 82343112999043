import { notificationState } from "@/plugins/notificationPlugin";

interface CroAuditData {
  url: string;
  persona: string;
  userId: string;
  companyId: string;
}

export function startAudit(state: any, data: CroAuditData): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      const url = new URL(`${process.env.VUE_APP_AI_API_URL}/api/tools/cro_audit/start_stream`);
      const params = {
        url: data.url,
        persona: data.persona,
        user_id: data.userId,
        company_id: data.companyId,
      };
      url.search = new URLSearchParams(params).toString();

      const eventSource = new EventSource(url.toString());

      eventSource.onmessage = (event) => {
        const eventData = JSON.parse(event.data);

        // Push to auditResults progress array
        if (eventData.message) {
          state.auditProgress.push(eventData);
        }

        if (eventData && eventData.report_id && eventData.report_id !== "") {
          state.auditResults = eventData;
          console.log("Audit results:", eventData);
          notificationState.value = {
            message: `CRO Audit completed successfully! <a href="/ai-tools/cro-audit/${eventData.report_id}"'>View results</a>`,
            type: "success", // or 'error', 'info', etc.
            autoHide: false,
          };
          eventSource.close();
          resetAudit(state);
          resolve(eventData.report_id);
        }
      };

      eventSource.onerror = (event) => {
        console.error("Error occurred:", event);
        eventSource.close();
        notificationState.value = {
          message: "CRO Audit failed!",
          type: "error", // or 'error', 'info', etc.
          autoHide: true,
        };
        resetAudit(state);
        reject(new Error("An error occurred while receiving updates"));
      };
    } catch (err: any) {
      resetAudit(state);
      reject(new Error(err.message));
    }
  });
}

export async function fetchCroAudits(state: any, payload: { [key: string]: any } = {}) {
  // Check if payload is provided and is not null
  if (!payload) {
    console.error("Payload is undefined or null");
    return;
  }

  // Construct the URL with parameters
  const params = new URLSearchParams(Object.entries(payload));
  const apiUrl = `${process.env.VUE_APP_API_URL}/api/croAudits?${params.toString()}`;

  try {
    const response = await fetch(apiUrl);

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data) {
      state.croAudits = data; // Assuming you have an croAudits property in your state
    }
  } catch (err) {
    console.error("Error fetching CRO audits:", err);
  }
}

export async function fetchCroAudit(state: any, id: string) {
  // Check if payload is provided and is not null
  if (!id) {
    console.error("ID is undefined or null");
    return;
  }

  // Construct the URL with parameters
  const apiUrl = `${process.env.VUE_APP_API_URL}/api/croAudits/${id}`;

  try {
    const response = await fetch(apiUrl);

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data) {
      state.loadedCroAudit = data; // Assuming you have an croAudits property in your state
    }
  } catch (err) {
    console.error("Error fetching CRO audits:", err);
  }
}

export function resetAudit(state: any) {
  state.auditRunning = false;
  state.auditProgress = [];
  state.auditResults = null;
}
