<template>
  <div v-if="!auditRunning" class="grid grid-cols-12 w-full min-w-full">
    <form class="flex-shrink-0 min-w-full col-span-8 pr-4">
      <div class="w-full gap-4">
        <div class="flex flex-col">
          <gm-input
            v-model="persona"
            name="persona"
            type="textarea"
            rows="4"
            label="Persona"
            placeholder="e.g. tech-savvy millennial, male, 25-35 years old, interested in sports, likes to travel, etc."
            :has-error="personaError"
          ></gm-input>
          <gm-input
            v-model="url"
            type="url"
            placeholder="https://"
            class="mt-6 mb-6"
            label="Website URL"
            required
            :has-error="urlError"
          ></gm-input>
          <div class="w-auto mt-3">
            <gm-button
              :loading="auditRunning"
              size="large"
              label="Start Audit"
              @click.prevent="startAudit"
              :disabled="!formIsValid"
            ></gm-button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div v-else>
    <div v-if="auditRunning">
      <div class="flex flex-col gap-2 text-gray-800 pb-6 mb-3">
        <div class="flex gap-1 mb-0 items-center">
          <gm-spinner></gm-spinner>
          <span class="font-semibold text-brandDarkBlue-500 text-xl"
            >Audit is running<span class="animated-ellipsis">...</span></span
          >
        </div>
        <span class="text-gray-500 text-sm">This may take a few minutes.</span>
      </div>
      <div
        class="mb-3 border border-brandDarkBlue-50 rounded-md px-3 py-3 text-gray-800 text-sm flex gap-2 items-center"
        v-for="(progress, idx) in auditProgress"
        :key="idx"
      >
        <CheckCircleIcon class="text-green-500 w-6 h-6" v-if="progress.status === 'success'" />
        <XCircleIcon class="text-red-500 w-6 h-6" v-if="progress.status === 'error'" />
        <ClockIcon class="text-gray-400 w-6 h-6" v-if="progress.status === 'info'" />
        {{ progress.message }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useCroAuditStore } from "@/store/aiTools/cro-audit";
import useUserStore from "@/store/user";
import { CheckCircleIcon, XCircleIcon, ClockIcon } from "@heroicons/vue/24/outline";

const croAuditStore = useCroAuditStore();
const userStore = useUserStore();
const router = useRouter();

// Form fields
const persona = ref("");
const url = ref("");

// Form validation
const formIsValid = computed(() => {
  // Check if all required fields are filled and not empty to enable the submit button
  return persona.value && url.value && !personaError.value && !urlError.value;
});

// Check validity of form persona field
const personaError = computed(() => {
  if (persona.value) {
    if (persona.value.length > 1000) {
      return "Persona must be less than 1000 characters";
    }
    if (persona.value.length < 25) {
      return "Persona must be at least 25 characters";
    }

    return null;
  }

  return null;
});

// Check validity of form URL field
const urlError = computed(() => {
  if (url.value) {
    // Check if it's a valid url
    const urlRegex = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );
    if (!urlRegex.test(url.value)) {
      return "Please enter a valid URL";
    }

    return null;
  }

  return null;
});

// While audit is running
const auditRunning = computed(() => croAuditStore.auditRunning);
const auditProgress = computed(() => {
  // For each progress item, check for items with the same id and only keep the one with status "success"
  const progress = croAuditStore.auditProgress;
  const progressIds = progress.map((item) => item.id);
  const uniqueProgressIds = [...new Set(progressIds)];
  const uniqueProgress = uniqueProgressIds.map((id) => {
    const items = progress.filter((item) => item.id === id);
    const successItem = items.find((item) => item.status === "success");
    const errorItem = items.find((item) => item.status === "error");
    const infoItem = items.find((item) => item.status === "info");
    if (successItem) {
      return successItem;
    } else if (errorItem) {
      return errorItem;
    } else if (infoItem) {
      return infoItem;
    } else {
      return items[0];
    }
  });

  return uniqueProgress;
});

// Start the CRO audit
async function startAudit() {
  croAuditStore.auditRunning = true;
  const data = {
    url: url.value.replace(/\/+$/, ""),
    persona: persona.value,
    userId: userStore._id,
    companyId: userStore.companyId,
  };

  try {
    const report_id = await croAuditStore.startAudit(data);
    // Successful, so redirect to the report
    router.push(`/ai-tools/cro-audit/${report_id}`);
  } catch (error) {
    croAuditStore.resetAudit();
    croAuditStore.auditRunning = false;
  } finally {
    croAuditStore.auditRunning = false;
    croAuditStore.resetAudit();
  }
}
</script>

<style lang="scss">
.sidebar-right {
  height: calc(100vh - 155px);
}
</style>
