import { defineStore } from "pinia";
import * as aiToolsActions from "./actions";

export const useAiToolsStore = defineStore("aiTools", {
  state: () => {
    return {
      loadedTool: null,
    };
  },
  actions: {
    async fetchToolInfo(data: any) {
      await aiToolsActions.fetchToolInfo(this.$state, data);
    },
  },
});

export default useAiToolsStore;
