<template>
  <div>
    <gm-grid v-if="auditsLoading">
      <gm-card loading="true" />
    </gm-grid>

    <div class="flow-root" v-if="!auditsLoading">
      <div v-if="haveSavedAudits" class="overflow-x-auto">
        <div class="inline-block min-w-full">
          <gm-grid>
            <gm-card
              v-for="audit in savedAudits"
              :key="audit._id"
              clickable
              :thumbnail="audit.screenshot_url"
              thumbnail-position="center top"
              thumbnail-height="large"
              @click.stop="viewAudit(audit._id)"
            >
              <div class="p-6">
                <div class="flex gap-4 w-full justify-between">
                  <div class="flex flex-col gap-1 text-brandDarkBlue-500 font-medium text-lg">
                    {{ formatUrl(audit.website_url) }}
                    <div class="text-xs text-gray-500 font-normal">
                      Audited {{ formatDate(audit.auditDate) }}
                    </div>
                  </div>
                  <div
                    :class="[
                      'flex-shrink-0 border h-auto font-semibold rounded-md px-6 items-center justify-center flex',
                      auditResultsScoreColor(audit.recommendations.audit_score),
                    ]"
                  >
                    {{ audit.recommendations.audit_score }}
                  </div>
                </div>
              </div>
            </gm-card>
          </gm-grid>
        </div>
      </div>
      <div v-if="!haveSavedAudits">
        <button
          type="button"
          class="relative block w-full max-w-96 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none"
          @click="newAudit"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="mx-auto h-12 w-12 text-gray-400"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
            />
          </svg>

          <span class="mt-2 block text-sm font-semibold text-gray-900">Start a new audit</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useCroAuditStore } from "@/store/aiTools/cro-audit";
import { useUserStore } from "@/store/user";
import { useInterfaceStore } from "@/store/interface";

const router = useRouter();
const croAuditStore = useCroAuditStore();
const userStore = useUserStore();
const interfaceStore = useInterfaceStore();

const savedAudits = computed(() => croAuditStore.croAudits);
const auditsLoading = ref(true);

const haveSavedAudits = computed(() => {
  return croAuditStore.croAudits && croAuditStore.croAudits.length > 0;
});

function auditResultsScoreColor(score) {
  // If score is less than 4, return red background
  // If score is between 4 and 7, return yellow background
  // If score is greater than 7, return green background
  if (score < 4) {
    return "bg-red-100 border-red-500 text-red-500";
  } else if (score >= 4 && score <= 7) {
    return "bg-yellow-100 border-yellow-500 text-yellow-500";
  } else {
    return "bg-green-100 border-green-500 text-green-500";
  }
}

function viewAudit(auditId) {
  router.push(`/ai-tools/cro-audit/${auditId}`);
}

function newAudit() {
  router.push("/ai-tools/cro-audit/new");
}

function formatUrl(url) {
  // Return the URL without the http or https part
  return url.replace(/(^\w+:|^)\/\//, "");
}

function formatDate(date) {
  // Return the date in the format 29 December 2023 @ 11:43pm
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };
  return new Date(date).toLocaleDateString(undefined, options);
}

onMounted(async () => {
  try {
    savedAudits.value = await croAuditStore.fetchCroAudits({ userId: userStore._id });
  } catch (err) {
    console.error(err);
  } finally {
    auditsLoading.value = false;
  }
});
</script>
