export async function fetchToolInfo(state: any, toolName: string) {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_AI_API_URL}/api/tools/${toolName.replace("-", "_")}/info`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    if (data && data.data) {
      state.loadedTool = data.data;
    }
  } catch (err) {
    console.error("Error fetching tool:", err);
  }
}
