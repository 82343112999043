import { defineStore } from "pinia";
import * as croAuditActions from "./actions";

export const useCroAuditStore = defineStore("croAudit", {
  state: () => {
    return {
      auditRunning: false,
      auditProgress: [],
      auditResults: {},
      croAudits: [],
      loadedCroAudit: {},
    };
  },
  actions: {
    async startAudit(data: any) {
      const response = await croAuditActions.startAudit(this.$state, data);
      return response;
    },
    async fetchCroAudits(payload: any) {
      const response = await croAuditActions.fetchCroAudits(this.$state, payload);
      return response;
    },
    async fetchCroAudit(id: any) {
      const response = await croAuditActions.fetchCroAudit(this.$state, id);
      return response;
    },
    resetAudit() {
      croAuditActions.resetAudit(this.$state);
    },
  },
});

export default useCroAuditStore;
