<template>
  <div class="pb-12">
    <gm-spinner size="large" v-if="!haveAuditResults"></gm-spinner>
    <div v-if="haveAuditResults">
      <div class="flex w-full justify-between items-center pb-6 mb-8 border-b border-gray-200">
        <div class="flex gap-4">
          <gm-button
            label=""
            size="small"
            icon="back"
            secondary
            @click="backToTool"
            title="Back to CRO audits"
            icon-only
          />
          <div class="flex flex-col text-brandDarkBlue-500 font-medium text-xl gap-1">
            <a class="hover:opacity-50" :href="auditResults.website_url" target="_blank">{{
              formatUrl(auditResults.website_url)
            }}</a>
            <div class="text-sm text-gray-500">
              Audited {{ formatDate(auditResults.auditDate) }}
            </div>
          </div>
        </div>

        <div class="flex gap-3 items-center">
          <gm-button secondary icon="export" label="Export report" />
        </div>
      </div>
      <div class="flex gap-0">
        <div class="w-64 flex-shrink-0">
          <img :src="auditResults.screenshot_url" />
        </div>
        <div class="w-auto px-16">
          <div class="flex gap-6 border-b border-brandDarkBlue-50 pb-6 mb-6">
            <div
              :class="[
                'flex-shrink-0 border text-5xl w-40 h-auto font-semibold rounded-md p-8 items-center justify-center flex',
                auditResultsScoreColor,
              ]"
            >
              {{ auditResults.recommendations.audit_score }}
            </div>
            <div class="flex-grow text-md">
              <h4>Summary</h4>
              {{ auditResults.recommendations.score_justification }}
            </div>
          </div>

          <div class="flex flex-col border-b border-brandDarkBlue-50 pb-6 mb-6">
            <h4>Persona</h4>
            <p>{{ auditResults.recommendations.persona }}</p>
          </div>

          <div class="flex gap-6 border-b border-brandDarkBlue-50 pb-6 mb-6">
            <div class="w-1/2">
              <h4>Pros</h4>
              <div
                class="flex gap-2 items-start mb-3"
                v-for="(pro, idx) in auditResults.recommendations.pros"
                :key="idx"
              >
                <CheckCircleIconAlt class="text-green-500 w-6 h-6 flex-shrink-0 mt-0.5" />
                <p class="text-sm">{{ pro }}</p>
              </div>
            </div>
            <div class="w-1/2">
              <h4>Cons</h4>
              <div
                class="flex gap-2 items-start mb-3"
                v-for="(con, idx) in auditResults.recommendations.cons"
                :key="idx"
              >
                <XCircleIconAlt class="text-red-500 w-6 h-6 flex-shrink-0 mt-0.5" />
                <p class="text-sm">{{ con }}</p>
              </div>
            </div>
          </div>

          <div class="mb-6">
            <h4>Design feedback</h4>
            <p>{{ auditResults.recommendations.visual_design_feedback }}</p>
          </div>
          <div class="mb-6">
            <h4>Text content feedback</h4>
            <p>{{ auditResults.recommendations.page_content_feedback }}</p>
          </div>
          <div class="mb-6">
            <h4>Priority recommendations</h4>
            <ul class="list-disc ml-5">
              <li
                v-for="(recommendation, idx) in auditResults.recommendations
                  .priority_recommendations"
                :key="idx"
              >
                {{ recommendation }}
              </li>
            </ul>
          </div>
          <div class="mb-6">
            <h4>Full recommendations</h4>
            <ul class="list-disc ml-5">
              <li
                v-for="(recommendation, idx) in auditResults.recommendations.full_recommendations"
                :key="idx"
              >
                {{ recommendation }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/24/outline";
import {
  CheckCircleIcon as CheckCircleIconAlt,
  XCircleIcon as XCircleIconAlt,
} from "@heroicons/vue/24/solid";
import { useCroAuditStore } from "@/store/aiTools/cro-audit";
import { useRoute, useRouter } from "vue-router";

const croAuditStore = useCroAuditStore();
const $route = useRoute();
const router = useRouter();

const auditId = ref("");
const haveAuditResults = ref(false);
const auditResults = computed(() => croAuditStore.loadedCroAudit);

const auditResultsScoreColor = computed(() => {
  const score = auditResults.value.recommendations.audit_score;
  // If score is less than 4, return red background
  // If score is between 4 and 7, return yellow background
  // If score is greater than 7, return green background
  if (score < 4) {
    return "bg-red-100 border-red-500 text-red-500";
  } else if (score >= 4 && score <= 7) {
    return "bg-yellow-100 border-yellow-500 text-yellow-500";
  } else {
    return "bg-green-100 border-green-500 text-green-500";
  }
});

function formatDate(date) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(date).toLocaleDateString(undefined, options);
}

function formatUrl(url) {
  // Return the URL without the http or https part
  return url.replace(/(^\w+:|^)\/\//, "");
}

async function backToTool() {
  croAuditStore.loadedCroAudit = {};
  router.push("/ai-tools/cro-audit");
}

onMounted(async () => {
  auditId.value = $route.params.id;
  try {
    await croAuditStore.fetchCroAudit(auditId.value);
    haveAuditResults.value = true;
  } catch (e) {
    console.log("error fetching audit", e);
  }
});
</script>

<style></style>
